import React from 'react';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */




/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/
import logo from '../assets/logos/logo.png';
import icon from '../assets/logos/ICONE.png';
import line from '../assets/logos/line.png';
import gif1 from '../assets/gif/digital-martking.gif';
import kandoMaquette_7 from '../assets/images/maquette7-kando.png';



/**
 * Importation des variables globals
 */



/**
 * Importation des helpers
 */




class SuperScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            pageIsReady: false,
        }

        this.list_menu = [
            {
                icon: null,
                name: "Accueil",
                link: "#",
                action: (callback)=>{
                    callback();
                    return true;
                },
            },
            {
                icon: null,
                name: "Nos services",
                link: "#",
                action: (callback)=>{
                    callback();
                    return true;
                },
            },
            {
                icon: null,
                name: "À propos de nous",
                link: "#",
                action: (callback)=>{
                    callback();
                    return true;
                },
            },
        ];

        this.logo = logo;
        this.icon = icon;
        this.line = line;
        this.gif1 = gif1;
        this.kandoMaquette_7 = kandoMaquette_7;
        this.kondosia = ["K","O","N","D","O","S","I","A"];
    }

    componentDidMount() {
        
    }

    _generateID(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    _renderHomeAnimation() {
        return (
            <div className='homeAnimationContainer position-fixed flex-row align-center justify-center'>
                <div className='homeAnimationContainer_ flex-row align-center justify-center'>
                    <img className='homeAnimationContainer-logo' src={icon} alt="kondosia"/>
                    <div className="homeAnimationContainer-qwer">
                        <div className="homeAnimationContainer-textContainer flex-row">
                            {
                                this.kondosia.map((le, index) => (
                                    <span className="homeAnimationContainer-text color-primary" key={"kondo" + index}
                                        style={{
                                            animationDelay: 2000 + ( index * 100 ) + "ms"
                                        }}
                                    >
                                        {le}
                                    </span>
                                ))
                            }
                        </div>
                        <div className='homeAnimationContainer-line'>
                            <img src={this.line} alt="kondosia"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>

            </>
        )
    }
}

export default SuperScreen;