import React from 'react';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';



/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */




/**----------------------------------------------------------------------------------------*/
/** Importation des screens */
import HomeView from './home_view.screen';



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.css';
import './responsive.style/home.responsive.style.css';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class Home extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            currentMenu: 0,
            openMenu: false,
        }

        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this.setState({
            pageIsReady: false,
        }, () => {
            setTimeout(() => {
                this.setState({
                    pageIsReady: true,
                });
            }, 4000);
        });
    }

   
    render() {
        return (
            <>
                {
                    super.render()
                }
                {
                    !this.state.pageIsReady ?
                        <div>
                            { super._renderHomeAnimation() }
                        </div>
                    :
                        <div className='kando'>
                            <header className='position-sticky flex-row justify-between align-center kando-header'>
                                <div>
                                    <img className='kando-header-icon' src={this.logo} alt="kondosia" />
                                    <div className='kando-header-slug color-primary bold'>
                                        <span>
                                            Pour vous simplifier la vie !
                                        </span>
                                    </div>
                                </div>
                                <nav className='kando-header-nav flex-row justify-between align-center'>
                                    <ul className="flex-row align-center kando-header-nav-menu"
                                        style={{
                                            transform: this.state.openMenu ? "translate(0%, 0)" : "translate(100%, 0)"
                                        }}
                                    >
                                        <div className='kando-header-nav-menu-cross'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                onClick={() => {
                                                    this.setState({
                                                        openMenu: !this.state.openMenu,
                                                    })
                                                }}
                                            >
                                                <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff"/>
                                                <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff"/>
                                            </svg>
                                        </div>


                                        {
                                            this.list_menu.map((menu, index) => (
                                                <li className="kando-header-nav-menu-item hoverStyle1-container" key={this._generateID(36)}>
                                                    <HashLink className="kando-header-nav-menu-item-link hoverStyle1 color-primary" variant="contained" to={menu.link}>
                                                        { menu.name }
                                                    </HashLink>
                                                    {
                                                        this.state.currentMenu === index &&
                                                        <div className='currentMenuBaseLine'></div>
                                                    }
                                                </li>
                                            ))
                                        }

                                        <li className='kando-header-nav-menu-item-button'>
                                            <HashLink className="kando-header-nav-button kando-header-nav-button-w button-style-3" variant="contained" to={"./"}>
                                                <span className='button-style-3-span kando-header-nav-button-w-span'>
                                                    Nous contacter
                                                </span>
                                            </HashLink>
                                        </li>
                                    </ul>
                                    <div className='menu-back'
                                        style={{
                                            transform: this.state.openMenu ? "translate(0%, 0)" : "translate(-100%, 0)"
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                openMenu: !this.state.openMenu,
                                            })
                                        }}
                                    ></div>

                                    <div className='kando-header-nav_'>
                                        <HashLink className="kando-header-nav-button button-style-3" variant="contained" to={"./"}>
                                            <span className='button-style-3-span'>
                                                Nous contacter
                                            </span>
                                        </HashLink>
                                    </div>
                                </nav>
                                <div className='kando-header-menuIcon color-primary'>
                                    <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        onClick={() => {
                                            this.setState({
                                                openMenu: !this.state.openMenu,
                                            })
                                        }}
                                    >
                                        <path d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z" fill="#37587d"/>
                                    </svg>
                                </div>
                            </header>

                            <div>
                                <Switch>
                                    <Route exact path="/" component={HomeView}/>
                                </Switch>
                            </div>
                        </div>
                }
            </>
        )
    }
}


Home = connect(mapStateToProps, null)(Home);
export default Home;