import React from 'react';
import { connect } from 'react-redux';
// import { HashLink } from 'react-router-hash-link';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import Card from '../components/card/card.component';


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.css';
import './responsive.style/home.responsive.style.css';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class HomeView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        }

        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <section className='kando-section flex-row align-center justify-between'>
                    <div className='kando-section-left flex-row justify-center'>
                        <img src={this.gif1} alt="kondosia"/>
                    </div>

                    <div className='kando-section-right'>
                        <div className='kando-section-right-body'>
                            <span className='dynamic-fts-1 color-primary'>
                                Agence de digitalisation
                            </span>
                        </div>
                        <div className='kando-section-right-footer'>
                            <span className='color-gray2 dynamic-fts-4'>
                                Kondosia vous accompagne en élaborant des systèmes intelligents, conviviaux et efficients qui vous simplifient la vie dans vos activités.
                            </span>
                        </div>
                    </div>
                </section>

                <section className='kando-section-2'>
                    <div className='kando-section-2 subtitle-content-1'>
                        <span className='color-primary'>
                            Nos réalisations
                        </span>
                    </div>

                    <div className='card-content flex-row justify-center flex-wrap'>
                        <div className='card-content_'>
                            <Card link={"https://rad-ong.org/"} elt={{
                                logo: "/images/rad-logo.png",
                                name: "RAD-ONG",
                                description: "Organisation dynamique, rayonnante au plan national et international oeuvrant pour le développement."
                            }}/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


HomeView = connect(mapStateToProps, null)(HomeView);
export default HomeView;