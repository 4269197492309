import React from 'react';
// import parse from 'html-react-parser';
// import { Link } from 'react-router-dom';

import './style/style.css';
import './style/responsive.style.css';


class Card extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            projectLink: '',
            percent: null,
        }
    }


    componentDidMount() {
        this.setState({
            link: this.props.link,
            percent: this.props.elt.percent,
        });
    }

    render() {
        return (
            <div className="Card">
                <div className="Card_imageContainer">
                    <a href={this.props.link}
                        onClick={() => {
                            this.props.action && this.props.action();
                        }}
                    >
                        <img className="Card_image" alt={""} src={this.props.elt.logo} />
                    </a>
                    {
                        this.props.elt.odd ?
                            <div className="Card_odd">
                                {
                                    this.props.elt.odd.slice(0).reverse().map((odd, index) => (
                                        <div key={"ODD" + index} className="odd">
                                            <a href={"/#"}
                                                onClick={()=>{
                                                    return true;
                                                }}
                                            >
                                                <img className="odd_" alt="odd" src={"./upload/logos/ODD/odd" +odd+ ".png"}/>
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>
                        :
                            null
                    }
                    {
                        this.state.percent!=null &&
                            <div className='Card_imageContainer-bottom'></div>
                    }
                </div>
                <div className="Card_body">
                    <a href={this.props.link}
                        onClick={() => {
                            // this.props.action && this.props.action();
                        }}
                        style={{textDecoration: "none"}}
                    >
                        <div className="Card_body_title">
                            <span>{this.props.elt.name && this.props.elt.name}</span>
                        </div>
                    </a>
                    {/* <div className="Card_body_title Card_body_date">
                        <span>{this.props.elt.month}/{this.props.elt.year}</span>
                    </div> */}
                    <div className="card_separator_line"></div>
                    <div className="Card_body_text text-form lineHeight1">
                        <p>
                            {/* {parse(this.props.elt.description)} */}
                            {this.props.elt.description}
                        </p>
                    </div>
                    {/* <div className="Card_competence"
                        style={{
                            transform: "scale(1)",
                            opacity: "1",
                            height: "100%",
                        }}
                    >
                        {
                            this.props.objectives &&
                            this.props.objectives.map((objective, index) => (
                                <div key={"objectives" + index} className="competence">
                                    <a className="link" href={this.props.competenceLink}
                                        onClick={() => {
                                            // this.props.action2 && this.props.action2();
                                        }}
                                    >
                                        <span>
                                            {objective.name}
                                        </span>
                                    </a>
                                </div>
                            ))
                        }
                    </div> */}
                </div>
                <div className='Card_foot'>
                    <a className="link" href={this.props.link}>
                        <span>AFFICHER</span>
                        <span className='Card_foot_fake'></span>
                    </a>
                </div>
            </div>
        )
    }
}

export default Card;